<template>
  <v-stepper v-model="step" alt-labels elevation="0">
    <v-stepper-header class="flex-nowrap">
      <v-stepper-step
        v-for="(st, key, index) in steps"
        :key="index"
        :step="index + 1"
        color="primary"
        complete-icon="$complete"
        :complete="step > index"
        :to="{ name: key }"
        @click="navTo(key, step > index)"
      >
        {{ st }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  data() {
    return {
      steps: []
    };
  },
  props: {
    step: { type: Number, default: 1 },
    stepper: { type: String, default: "paymentStepper" }
  },
  methods: {
    navTo(page, back) {
      if (this.$route.name !== page && back) {
        this.$router.push({ name: page });
        // if (back) {
        //   this.$router.back(page);
        // } else {
        //   this.$router.push({ name: page });
        // }
      }
    }
  },
  created() {
    let _this = this;
    this.steps = _this.geti18nObject(this.stepper);
  }
};
</script>
